<script>
import {
  ArrowUpIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MonitorIcon,
  HeartIcon,
  EyeIcon,
  BoldIcon,
  FeatherIcon,
  CodeIcon,
  UserCheckIcon,
  GitMergeIcon,
  SettingsIcon,
} from "vue-feather-icons";
import {
  Carousel,
  Slide
} from "vue-carousel";

import Navbar from "@/views/landing/navbar";
import Footer from "@/views/landing/footerBar";


/**
 * Page-aboutus component
 */
export default {
  metaInfo: {
    title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
    link: [
      { rel: 'canonical', href: 'https://inccrypto.com/about-us' }
    ]
  },
  data() {
    return {
      teamData: [{
        image: "images/client/01.jpg",
        name: "Ronny Jofra",
        designation: "C.E.O",
      },
        {
          image: "images/client/04.jpg",
          name: "Micheal Carlo",
          designation: "Director",
        },
        {
          image: "images/client/02.jpg",
          name: "Aliana Rosy",
          designation: "Manager",
        },
        {
          image: "images/client/03.jpg",
          name: "Sofia Razaq",
          designation: "Developer",
        },
      ],
      // youtubeId: "95uY09yVxAs"
      youtubeId: "IvEzCllWrgs"
    };
  },
  components: {
    Navbar,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MonitorIcon,
    HeartIcon,
    EyeIcon,
    BoldIcon,
    FeatherIcon,
    CodeIcon,
    UserCheckIcon,
    GitMergeIcon,
    SettingsIcon,
  },
};
</script>

<template>
  <div>
    <Navbar/>
    <section class="section" style="margin-top: -60px;">
      <div class="container">
        <div class="container mt-60 mt-60">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-5" style="overflow: hidden;">
              <div class="position-relative">
                <div class="text-center text-md-left">
<!--                  <img src="images/app/location.jpg" class="img-fluid" alt="" width="95%" style="border-radius:30px;">-->
                  <img src="@/assets/tmp/location.jpg" class="img-fluid" alt="" width="95%" style="border-radius:30px;">
                </div>
              </div>
            </div>
            <!--end col-->
            <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
              <div class="section-title">
                <h1 class="title"><br>
                  Who We Are</h1>
                <!--  text-muted   -->
                <p class="para-desc">IncCrypto was founded in 2019.</p>
                <p class="para-desc">We were one of the first companies to provide cloud mining services. After years of development, we now have five mining farms around the world with members in more than 150 countries/regions, and we are trusted by over 320,000 users worldwide. We believe that everyone should benefit from cloud mining and have access to the latest technology and large industrial data centers from their laptop or mobile phone.</p>
                <p>We use renewable energy, such as solar and wind energy, to mine in the cloud, which greatly reduces the cost of mining and integrates the remaining electricity into the grid. This means you can get huge mining power without expensive hardware and without having to deal with noise and heat at home. All you need is your computer or mobile phone to sign a mining contract and get rewarded.</p>
                <p>Our team consists of professionals from top web companies, and more than half of our team works in the R&D department, bringing with them rich technical expertise.</p>
                <p>Our vision is to expand our business to cover the entire cryptocurrency industry chain, and to serve global users with technological innovation.</p>
                <div class="window">
                  <ul class="list-unstyled text-muted" style="display: flex;flex-flow: row wrap;">
                    <li class="mb-0">
											<span class="text-primary h5 mr-2">
												<i class="uil uil-check-circle align-middle"></i>
											</span>
                      <span style="color: black;font-weight: bold;font-size: 24px;">320K+</span><br>
                      <span class="ml-3">Active</span><br>
                      <span class="ml-3">Members</span>
                    </li>
                    <li class="mb-0 ml-5">
											<span class="text-primary h5 mr-2">
												<i class="uil uil-check-circle align-middle"></i>
											</span>
                      <span style="color: black;font-weight: bold;font-size: 24px;">150+</span><br>
                      <span class="ml-3">Countries</span><br>
                      <span class="ml-3">Supported</span>
                    </li>
                    <li class="mb-0 ml-5">
											<span class="text-primary h5 mr-2">
												<i class="uil uil-check-circle align-middle"></i>
											</span>
                      <span style="color: black;font-weight: bold;font-size: 24px;">3600K+</span><br>
                      <span class="ml-3">Total</span><br>
                      <span class="ml-3">Deposited</span>
                    </li>
                    <li class="mb-0 ml-5">
											<span class="text-primary h5 mr-2">
												<i class="uil uil-check-circle align-middle"></i>
											</span>
                      <span style="color: black;font-weight: bold;font-size: 24px;">8M+</span><br>
                      <span class="ml-3">Total</span><br>
                      <span class="ml-3">Withdrawal</span>
                    </li>
                  </ul>
                </div>
                <div class="phone" style="font-size: 12px;">
                  <ul class="list-unstyled text-muted "
                      style="display: flex;flex-flow: row wrap;justify-content: space-between;">
                    <li class="mb-0">
											<span class="text-primary h5 mr-2">
												<i class="uil uil-check-circle align-middle"></i>
											</span>
                      <span style="color: black;font-weight: bold;font-size: 14px;">230K+</span><br>
                      <span class="ml-3">Active</span><br>
                      <span class="ml-3">Members</span>
                    </li>
                    <li class="mb-0">
											<span class="text-primary h5 mr-2">
												<i class="uil uil-check-circle align-middle"></i>
											</span>
                      <span style="color: black;font-weight: bold;font-size: 14px;">120+</span><br>
                      <span class="ml-3">Countries</span><br>
                      <span class="ml-3">Supported</span>
                    </li>
                    <li class="mb-0">
											<span class="text-primary h5 mr-2">
												<i class="uil uil-check-circle align-middle"></i>
											</span>
                      <span style="color: black;font-weight: bold;font-size: 14px;">1660K+</span><br>
                      <span class="ml-3">Total</span><br>
                      <span class="ml-3">Deposited</span>
                    </li>
                    <li class="mb-0">
											<span class="text-primary h5 mr-2">
												<i class="uil uil-check-circle align-middle"></i>
											</span>
                      <span style="color: black;font-weight: bold;font-size: 14px;">2890K+</span><br>
                      <span class="ml-3">Total</span><br>
                      <span class="ml-3">Withdrew</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
    </section>

    <section class="d-table w-100 pb-0">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Our Date Center</h4>
            <p class="text-muted mx-auto mb-0">
              Services That We Continue To Improve
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="d-table w-100 pb-0">
      <div class="row justify-content-center m-2">
        <el-row type="flex" class="flex-wrap" :gutter="20">
          <el-col :span="3"></el-col>
          <el-col :span="6" :xs="24" class="mt-4">
            <div class="card work-process border-0 rounded shadow">
              <div class="card-body text-center">
                <h6 class="title">Data Center Locations</h6>
                <p style="font-size: 13px;">
                  Our Mining Data Centers are located in Brazil & Windhoek & Kazakhstan.
                </p>
                <p>
                  <img src="images/img1.png" class="img_inner">
                </p>
              </div>
            </div>
          </el-col>
          <!--end col-->

          <el-col :span="6" :xs="24" class="mt-4">
            <div class="card work-process border-0 rounded shadow">
              <div class="card-body text-center">
                <h6 class="title">Data Center Hardware</h6>
                <p style="font-size: 13px;">
                  15,000+ The newest ASIC miner, GPU rigs are Ready to mine.
                </p>
                <p>
                  <img src="images/img2.png" class="img_inner">
<!--                  <youtube :video-id="youtubeId" ref="youtube" class="img_inner"></youtube>-->
                </p>
              </div>
            </div>
          </el-col>
          <!--end col-->

          <el-col :span="6" :xs="24" class="mt-4">
            <div class="card work-process border-0 rounded shadow">
              <div class="card-body text-center">
                <h6 class="title">Power System</h6>
                <p style="font-size: 13px;">
                  We use Hydropower & Wind power, Total capacity of 300,000 KW.0.05 $/kWh.
                </p>
                <p>
                  <img src="images/img3.png" class="img_inner">
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
          <!--end col-->
        </el-row>
      </div>
    </section>

    <section class="d-table w-100 pb-0">
      <div class="row justify-content-center m-2">
        <el-row type="flex" class="flex-wrap" :gutter="20">
          <el-col :span="3"></el-col>
          <el-col :span="6" :xs="24" class="mt-4">
            <div class="card work-process border-0 rounded shadow">
              <div class="card-body text-center">
                <h6 class="title">Cooling System</h6>
                <p style="font-size: 13px;">
                  Air cooling system and Water cooling system keeping temperature:20-24℃.
                </p>
                <p>
                  <img src="images/img4.png" class="img_inner">
                </p>
              </div>
            </div>
          </el-col>
          <!--end col-->

          <el-col :span="6" :xs="24" class="mt-4">
            <div class="card work-process border-0 rounded shadow">
              <div class="card-body text-center">
                <h6 class="title">Security/Fire Protection</h6>
                <p style="font-size: 13px;">
                  24/7 on-site guards, intrusion detection systems, IP-DVR cameras.
                </p>
                <p>
                  <img src="images/img5.png" class="img_inner">
                </p>
              </div>
            </div>
          </el-col>
          <!--end col-->

          <el-col :span="6" :xs="24" class="mt-4">
            <div class="card work-process border-0 rounded shadow">
              <div class="card-body text-center">
                <h6 class="title">Our Team</h6>
                <p style="font-size: 13px;">
                  Our mining team come from in blockchain industry and IT engineers.
                </p>
                <p>
                  <img src="images/img6.png" class="img_inner">
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
          <!--end col-->
        </el-row>
      </div>
    </section>

    <section class="d-table w-100 pb-0 pt-5" style="margin-top: 40px;">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h5 class="title mb-4">Get In Touch With Us</h5>
          </div>
        </div>
      </div>
    </section>

    <section class="d-table w-100 pb-5">
      <div class="justify-content-center m-2">
        <el-row type="flex" class="flex-wrap" :gutter="20">
          <el-col :span="3"></el-col>
          <el-col :span="9" :xs="24">
            <div class="work-process border-0 rounded shadow">
              <div class="card-body text-center mt-3">
                <div
                    style="width: 40px;height: 40px;line-height: 40px;border-radius: 50%;background-color: #2d6efd;margin: 0px auto;">
                  <img src="images/email.png" class="img-fluid">
                </div>
                <h6 class="title" style="margin-top: 12px;">Email Address</h6>
                <p style="font-size: 13px;">
                  info@inccrypto.com
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="9" :xs="24">
            <div class="work-process border-0 rounded shadow">
              <div class="card-body text-center mt-3">
                <div
                    style="width: 40px;height: 40px;line-height: 40px;border-radius: 50%;background-color: #2d6efd;margin: 0px auto;">
                  <img src="images/address.png" class="img-fluid">
                </div>
<!--                <h6 class="title" style="margin-top: 12px;">Our Location</h6>-->
<!--                <p style="font-size: 13px;">-->
<!--                  22 Regal Court, Manor Road, Beverley, England, HU17 7GD, UK-->
<!--                </p>-->
              </div>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </div>
    </section>
    <Footer/>
    <!-- Footer End -->

    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
       v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}

.img_inner {
  width: 100%;
  height: 180px;
}
</style>
